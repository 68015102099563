import { Link, graphql, navigate, useStaticQuery } from 'gatsby';
import parse from 'html-react-parser';
import React, { useEffect, useRef, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { FluxDnaSettings } from '../Footer/FluxDnaSettings';
import '../Header/Header.scss';
import MainSiteInnerNav from '../Header/MainSiteInnerNav';

const PageTemplate = ({ formId, location }) => {
  // Menu
  const menuElement = useRef();
  const menuElementBooking = useRef();
  const [menuOpenStatus, setMenuOpenStatus] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuOpenBooking, setMenuOpenBooking] = useState(false);
  const fluxFooterSetting = FluxDnaSettings();
  const [isImage, setisImage] = useState(null);
  const [stepForm, setStepForm] = useState(1);
  const [isLoding, setIsLoading] = useState(false);
  const [nxtDate, setNxtDate] = useState('');
  const [checked, setChecked] = useState(false);
  const [pagePath, setPagePath] = useState(0);

  useEffect(() => {
    if (location) {
      setPagePath(location.pathname);
    }
  });

  const [form, setForm] = useState({
    url: '',
  });

  const [formError, setFormError] = useState({
    url: '',
  });

  const goToProperty = (url) => {
    if (url === '/the-silo-hotel/guest-enquiry/') {
      document.querySelector('.silo-link').click();
    } else if (url === '/la-residence/guest-enquiry/') {
      document.querySelector('.lares-link').click();
    } else if (url === '/royal-malewane/guest-enquiry/') {
      document.querySelector('.royalm-link').click();
    } else if (url === '/private-residences/guest-enquiry/') {
      document.querySelector('.privateres-link').click();
    } else if (url === '/birkenhead-house/guest-enquiry/') {
      document.querySelector('.birkenh-link').click();
    }
  };

  useEffect(() => {
    if (form.url !== '') {
      goToProperty(form.url);
    }
  }, [form, setForm]);

  const query = useStaticQuery(graphql/* GraphQL */ `
    query {
      wp {
        fluxDnaSettings {
          fluxDna {
            travelEnquiryBgimage {
              altText
              mediaItemUrl
              imgixImage {
                fluid(
                  maxWidth: 800
                  maxHeight: 1024
                  imgixParams: { w: 800, h: 1024, fit: "crop" }
                ) {
                  srcWebp
                }
              }
              uri
              title
            }
            travelEnquiryLogo {
              altText
              mediaItemUrl
              uri
              title
            }
          }
          pageTitle
          pageSlug
          fluxDna {
            royalPortfolio {
              cta {
                target
                title
                url
              }
              primaryLogo {
                altText
                mediaItemUrl
                uri
                title
              }
              secondaryLogo {
                altText
                mediaItemUrl
                uri
                title
              }
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    document.body.classList.remove('nav-menu-open');
    document.body.classList.remove('loading');
  }, []);

  const headerDataList = query.wp.fluxDnaSettings.fluxDna;
  const callback = (image) => {
    if (image) {
      setisImage(image);
    }
  };
  return (
    <>
      {isLoding && (
        <div
          id="loading"
          className="loading-spinner
        "
        ></div>
      )}
      <section className="traveller-enquiry-form  bg-white">
        <div className="flex flex-wrap">
          <div className="lg:w-7/12 w-full closemenu">
            <div
              className="xl:px-80 lg:px-40
             px-20 lg:py-40 pb-20 pt-20 relative h-screen overflow-y-auto overflow-x-hidden enquiry-left"
            >
              <div className="enquiry-header mb-50">
                <div className="flex flex-wrap items-center justify-between">
                  <div className="logo relative z-999">
                    <Link to="/">
                      <img
                        src={
                          headerDataList.royalPortfolio.primaryLogo.mediaItemUrl
                        }
                        alt="Logo"
                        className="m-auto lg:w-[149px] w-[120px] desktop-logo"
                      />
                    </Link>
                    <Link to="/">
                      <img
                        src={
                          headerDataList.royalPortfolio.secondaryLogo
                            .mediaItemUrl
                        }
                        alt="Logo"
                        className="m-auto lg:w-[149px sticky-logo"
                      />
                    </Link>
                  </div>
                  <div className="header-top-right flex ">
                    <div className="pr-20 ">
                      <button
                        className="text-12 flex items-center cursor-pointer text-dark"
                        onClick={() => navigate(-1)}
                      >
                        <img
                          className="pr-10 max-w-[15px]"
                          src="/images/back-to-site.png"
                          alt="back to site"
                        />
                        Back to site
                      </button>
                    </div>
                    <div
                      onClick={() => {
                        document.body.classList.toggle('nav-menu-open');
                        setMenuOpen(!menuOpen);
                      }}
                      className={`navbar-icon flex items-center cursor-pointer hamburger menu-open-test ${
                        menuOpen ? 'active' : ''
                      }`}
                    >
                      <div className="space-y-2 menu-line">
                        <span className="line w-full mx-auto"></span>
                        <span className="line line-middle w-full"></span>
                        <span className="line w-full mx-auto"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="scroll-form-main">
                <div className="top-info flex flex-wrap items-center justify-between">
                  <h1 className="text-black-500 lg:text-34">Guest Enquiry</h1>
                </div>
                <div className={`gf-step step_100`}></div>
                <form className="lg:mt-30 mt-20">
                  <div className="form-wrapper">
                    {stepForm === 1 && (
                      <div className="form-row">
                        <h6 className="text-black-400 tracking-006em capitalize lg:text-22">
                          Select from our Hotels & Lodges
                        </h6>
                        <div className="flex flex-wrap lg:mx-minus-20">
                          <div className="lg:w-12/12 w-full lg:px-20">
                            <div className="form-group grid grid-cols-2 mdscreen:grid-cols-1  gap-x-20 gap-y-6 relative overflow-hidden lg:mt-30 mt-10">
                              <div className="radio-bx">
                                <input
                                  type="radio"
                                  value="/the-silo-hotel/guest-enquiry/"
                                  checked={
                                    form.url ===
                                    '/the-silo-hotel/guest-enquiry/'
                                  }
                                  onChange={(e) => {
                                    setForm({
                                      ...form,
                                      url: e.target.value,
                                    });
                                    setFormError({
                                      ...formError,
                                      url: '',
                                    });
                                  }}
                                  id="The Silo Hotel"
                                />
                                <label
                                  htmlFor="The Silo Hotel"
                                  className="text-dark font-400 text-16"
                                >
                                  <h4> The Silo Hotel </h4>
                                  <p>Cape Town</p>
                                </label>
                              </div>
                              <div className="radio-bx">
                                <input
                                  type="radio"
                                  value="/la-residence/guest-enquiry/"
                                  checked={
                                    form.url === '/la-residence/guest-enquiry/'
                                  }
                                  onChange={(e) => {
                                    setForm({
                                      ...form,
                                      url: e.target.value,
                                    });
                                    setFormError({
                                      ...formError,
                                      url: '',
                                    });
                                  }}
                                  id="La_Residence"
                                />
                                <label
                                  htmlFor="La_Residence"
                                  className="text-dark font-400 text-16"
                                >
                                  <h4> La Residence</h4>
                                  <p>Franschhoek</p>
                                </label>
                              </div>
                              <div className="radio-bx">
                                <input
                                  type="radio"
                                  value="/royal-malewane/guest-enquiry/"
                                  checked={
                                    form.url ===
                                    '/royal-malewane/guest-enquiry/'
                                  }
                                  onChange={(e) => {
                                    setForm({
                                      ...form,
                                      url: e.target.value,
                                    });
                                    setFormError({
                                      ...formError,
                                      url: '',
                                    });
                                  }}
                                  id="Royal-Malewane"
                                />
                                <label
                                  htmlFor="Royal-Malewane"
                                  className="text-dark font-400 text-16"
                                >
                                  <h4> Royal Malewane</h4>
                                  <p>Greater Kruger </p>
                                </label>
                              </div>
                              <div className="radio-bx">
                                <input
                                  type="radio"
                                  value="/private-residences/guest-enquiry/"
                                  checked={
                                    form.url ===
                                    '/private-residences/guest-enquiry/'
                                  }
                                  onChange={(e) => {
                                    setForm({
                                      ...form,
                                      url: e.target.value,
                                    });
                                    setFormError({
                                      ...formError,
                                      url: '',
                                    });
                                  }}
                                  id="Private-Residences"
                                />
                                <label
                                  htmlFor="Private-Residences"
                                  className="text-dark font-400 text-16"
                                >
                                  <h4> Private Residences</h4>
                                  <p>Multiple Locations</p>
                                </label>
                              </div>
                              <div className="radio-bx">
                                <input
                                  type="radio"
                                  value="/birkenhead-house/guest-enquiry/"
                                  checked={
                                    form.url ===
                                    '/birkenhead-house/guest-enquiry/'
                                  }
                                  onChange={(e) => {
                                    setForm({
                                      ...form,
                                      url: e.target.value,
                                    });
                                    setFormError({
                                      ...formError,
                                      url: '',
                                    });
                                  }}
                                  id="Birkenhead-House"
                                />
                                <label
                                  htmlFor="Birkenhead-House"
                                  className="text-dark font-400 text-16"
                                >
                                  <h4>Birkenhead House</h4>
                                  <p> Hermanus</p>
                                </label>
                              </div>
                              <span className="italic text-10">
                                * Disclaimer: Rates are subject to change
                              </span>
                            </div>
                            {formError.url && (
                              <div className="error mt-20">
                                <span className="text-red">
                                  {formError.url}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}

                    <div
                      className={`form-btn flex lg:mt-50 mt-20 items-center ${
                        stepForm === 1 ? 'justify-end' : 'justify-between'
                      }`}
                    >
                      <a
                        onClick={() => {
                          if (form.url === '') {
                            setFormError({
                              url: 'Please select hotels & lodge.',
                            });
                          } else {
                            goToProperty(form.url);
                          }
                        }}
                        className="button button-gray cursor-pointer"
                      >
                        Continue
                      </a>
                      <Link
                        to={`/the-silo-hotel/guest-enquiry/`}
                        state={{ prevPath: pagePath }}
                        className="silo-link hidden"
                      >
                        hidden link
                      </Link>
                      <Link
                        to={`/royal-malewane/guest-enquiry/`}
                        state={{ prevPath: pagePath }}
                        className="royalm-link hidden"
                      >
                        hidden link
                      </Link>
                      <Link
                        to={`/la-residence/guest-enquiry/`}
                        state={{ prevPath: pagePath }}
                        className="lares-link hidden"
                      >
                        hidden link
                      </Link>
                      <Link
                        to={`/birkenhead-house/guest-enquiry/`}
                        state={{ prevPath: pagePath }}
                        className="birkenh-link hidden"
                      >
                        hidden link
                      </Link>
                      <Link
                        to={`/private-residences/guest-enquiry/`}
                        state={{ prevPath: pagePath }}
                        className="privateres-link hidden"
                      >
                        hidden link
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="lg:w-5/12 w-full">
            <div className="img h-full">
              <img
                src={
                  headerDataList.travelEnquiryBgimage.imgixImage.fluid.srcWebp
                }
                alt={headerDataList.travelEnquiryBgimage.altText}
                width="800"
                height="1024"
                className="w-full h-screen object-cover"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </section>
      <div className="main-nav nav-container">
        <div className="menu-overlay h-full fixed w-full top-0 left-0 bg-gray2"></div>
        <div className="mainNavimg mdscreen:hidden">
          <img
            src={headerDataList.travelEnquiryBgimage.imgixImage.fluid.srcWebp}
            alt={headerDataList.travelEnquiryBgimage.altText}
            width="800"
            height="1024"
            className="w-full h-screen object-cover"
            loading="lazy"
          />
        </div>
        <div className="mainNav__wrap relative m-auto p-menu_mobile z-99 h-full">
          <div className="container-fluid h-full">
            <div className="mainNav__wrap--inner h-full flex flex-col justify-center  mobile-menu">
              <div className="navbarMoile lg:pl-80 pt-20">
                <div className="max-w-[600px] lgscreen:max-w-[350px] smscreen:max-w-[280px] flex flex-col">
                  <div className="mainNav__col navMenu relative mainNav__col--right flex flex-col pt-20">
                    <div className="btn-custom bk-btn relative z-999 hidden">
                      <div
                        onClick={() => {
                          alert();
                          document.body.classList.remove('nav-menu-open');
                          setMenuOpenBooking(!menuOpenBooking);
                        }}
                        className={`button button-transparent cursor-pointer hamburger menu-open-test transition-all duration-300 ${
                          menuOpenBooking ? 'active' : ''
                        }`}
                      >
                        Make A Booking
                      </div>
                    </div>
                    <MainSiteInnerNav callback={callback} />
                  </div>
                </div>
                <div className="w-7/12 mdscreen:w-full">
                  <div className="flex justify-between flex-col items-start">
                    <div className="header-social-icon">
                      <div className="footer-info pt-20">
                        {parse(fluxFooterSetting.footer.contactInformation)}
                        <Link
                          to={headerDataList.royalPortfolio.cta.url}
                          className="link-btn"
                        >
                          {headerDataList.royalPortfolio.cta.title}
                        </Link>
                        <div className="sicon">
                          <ul className="flex flex-wrap items-center mt-20 lg:space-x-5 lgscreen:space-x-3 ">
                            {fluxFooterSetting.footer.socialIcons.map(
                              (item, i) => {
                                return (
                                  <li key={i}>
                                    <Link key={i} to={item.image.uri}>
                                      <img
                                        src={item.image.mediaItemUrl}
                                        alt={item.image.altText}
                                        className=""
                                      />
                                    </Link>
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageTemplate;
export const Head = () => <title>Guest Enquiry | The Royal Portfolio</title>;
